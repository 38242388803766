import React, { useState } from 'react';
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import Modal from 'react-modal';

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import "../styles/index.scss"
import {
  FaPlay,
  FaUserPlus,
  FaShoppingCart,
  FaTruck,
  FaCheck,
} from "react-icons/fa"

import Image1 from "../images/frontend/header-img1x.png"
import Image2 from "../images/frontend/purchasing_process.png"
import Image3 from "../images/frontend/grow_business.png"
import Image4 from "../images/frontend/purchase_portal.png"
import Image5 from "../images/frontend/footer.svg"
import Image6 from "../images/frontend/footer-img.png"
import Image7 from "../images/frontend/suppliers.png"
//import { TRUE } from "node-sass";

const customStyles = {
  content : {
    position              : 'fixed',
    top                   : '10px',
    left                  : '0',
    right                 : '0',
    bottom                : '0',
    // transform             : 'translate(-50%, -50%)',
    zIndex                : '99',
    background            : '#252424ba',
    textAlign            : 'center'
  }
};



const IndexPage = ({props}) => {
  const intl = useIntl()

  const [modalIsOpen, setModalOpen] = React.useState(false);


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const afterOpenModal = () => {
    //setModalOpen(true);
  };

  // Modal stuff.

  Modal.setAppElement('body');

  console.log(props);


  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.INDEX.TITLE" })}
        description={intl.formatMessage({ id: "SEO.INDEX.DESCRIPTION" })}
        keywords={intl.formatMessage({ id: "SEO.INDEX.KEYWORDS" })}
        //pathname={this.props.location.pathname}
      />

      <div id="landing-home-wrapper">
        <div id="landing-home">
          <tpnet-content>
            <article className="tpnet-article">
              <article className="tpnet-article-inner">
                <div className="tpnet-article-body">
                  <main>
                    <div className="bg-desktop">
                      {/* <img src="images/backgrounds/background-home.svg" alt="" role="presentation"> */}
                    </div>

                    <section className="homepage-hero">
                      <div className="homepage-hero-bg">
                        <svg
                          width="3556"
                          height="4022"
                          viewBox="0 0 3556 4022"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M685.298 4013.393c314.868 141.36 2878.18-1553.72 2870.564-1898.381-1.16-50.87-70.007-137.149-187.467-246.682C2689.871 1235.444 385.1-171.985 98.421 17.612-237.65 240.019 370.354 3872.114 685.298 4013.393z"
                            fill="#039BE5"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>

                      <div className="homepage-hero-content">
                        <div>
                          <tpnet-heading
                            text="TalepNET satın alma süreçlerini yönetmenize yardımcı olur"
                            for="talepnet-satin-alma-sureclerini-yonetmenize-yardımci-olur"
                            level="h1"
                            className=""
                          >
                            <h1>
                              <FormattedMessage id="LANDING.TITLE" />
                            </h1>
                          </tpnet-heading>
                          <div className="homepage-hero-ctas">
                            <a
                              href="https://app.talepnet.com/pages/auth/signup"
                              id="ga-get-started-button"
                              className="cta-button cta-button--white cta-button--text-sky-blue gc-analytics-event"
                            >
                              <FormattedMessage id="LANDING.GET_STARTED_FREE" />
                            </a>

                            <a
                              onClick={openModal}
                              id="ga-watch-video-button"
                              className="cta-link cta-link--with-icon cta-link--white gc-analytics-event"
                            >
                              <FaPlay />
                              <FormattedMessage id="LANDING.WATCH_VIDEO" />
                            </a>

                            <Modal
                              // parentSelector={() => document.querySelector('#landing-home-wrapper')}
                              isOpen={modalIsOpen}
                              onAfterOpen={afterOpenModal}
                              onRequestClose={closeModal}
                              style={customStyles}
                              contentLabel="TalepNET Purchasing Management"
                            >
                              <button
                                onClick={closeModal}
                                style={{
                                  padding: "3px 12px",
                                  cursor: "pointer",
                                  background: "transparent",
                                  color: "#ffffff"
                                }}
                              >
                                X
                              </button>
                              <Video
                                videoSrcURL="https://www.youtube.com/embed/80aRiOFSJuI"
                                videoTitle="TalepNET - Online Satın Alma Yönetimi"
                              />
                            </Modal>

                            {/* <Video
                              videoSrcURL="https://www.youtube.com/embed/80aRiOFSJuI"
                              videoTitle="TalepNET - Online Satın Alma Yönetimi"
                            /> */}
                          </div>
                        </div>

                        <div
                          className="homepage-hero__illo"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <img src={Image1} alt="Person with sand watch" />
                        </div>
                      </div>
                    </section>

                    <div className="content-wrapper">
                      <section className="card">
                        <div className="card__content">
                          <header className="card__header four-column-header">
                            <tpnet-heading className="card__heading">
                              <h3>
                                <FormattedMessage id="LANDING.SECTION0_HEAD" />
                              </h3>
                              <h5 className="no-link">
                                {" "}
                                <FormattedMessage id="LANDING.SECTION0_DESC" />
                              </h5>
                            </tpnet-heading>
                          </header>
                          <div className="four-column">
                            <div className="four-column__wrapper">
                              <div className="four-column__column">
                                <FaPlay />
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION0_1_HEAD" />
                                  </h5>
                                </tpnet-heading>
                              </div>

                              <div className="four-column__column">
                                <FaUserPlus />
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION0_2_HEAD" />
                                  </h5>
                                </tpnet-heading>
                              </div>

                              <div className="four-column__column">
                                <FaShoppingCart />
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION0_3_HEAD" />
                                  </h5>
                                </tpnet-heading>
                              </div>

                              <div className="four-column__column">
                                <FaTruck />
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION0_4_HEAD" />
                                  </h5>
                                </tpnet-heading>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className="content-wrapper">
                      <section className="card">
                        <div className="card__content">
                          <div className="card__subpartial tpnet-page-home__text-illo-container">
                            <div className="tpnet-page-home__text-illustration"></div>
                          </div>

                          <div className="three-column">
                            <div className="three-column__wrapper">
                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION1_1_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION1_1_DESC" />
                                </p>
                              </div>

                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION1_2_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION1_2_DESC" />
                                </p>
                              </div>

                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION1_3_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION1_3_DESC" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className="content-wrapper">
                      <section className="card">
                        <div className="card__content">
                          <header className="card__header">
                            <tpnet-heading className="card__heading">
                              <h2>
                                <FormattedMessage id="LANDING.SECTION2_HEAD" />
                              </h2>
                            </tpnet-heading>
                          </header>

                          <div className="card__subpartial">
                            <div className="product-cols">
                              <div className="product-cols__col">
                                <header className="product-cols__header">
                                  <img src={Image2} alt="Purchasing process" />

                                  <tpnet-heading
                                    text="Satın alma sürecini yönetin"
                                    className="product-cols__heading"
                                  >
                                    <h5 className="no-link">
                                      <FormattedMessage id="LANDING.SECTION2_COL1_HEAD" />
                                    </h5>
                                  </tpnet-heading>
                                </header>

                                <ul className="product-cols__list" role="list">
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW1_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW1_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW2_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW2_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW3_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW3_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW4_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW4_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW5_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW5_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW6_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW6_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW7_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW7_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW8_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW8_DESC" />
                                      </p>
                                    </div>
                                  </li>

                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--build"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW9_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL1_ROW9_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className="product-cols__col">
                                <header className="product-cols__header">
                                  <img src={Image3} alt="Grow your business" />

                                  <tpnet-heading
                                    text="Satın alma sürecini yönetin"
                                    className="product-cols__heading"
                                  >
                                    <h5 className="no-link">
                                      <FormattedMessage id="LANDING.SECTION2_COL2_HEAD" />
                                    </h5>
                                  </tpnet-heading>
                                </header>

                                <ul className="product-cols__list" role="list">
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW1_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW1_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a
                                        href="https://portal.talepnet.com"
                                        className="product-icon__name gc-analytics-event"
                                      >
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW2_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW2_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW3_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW3_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW4_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW4_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW5_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW5_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW6_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW6_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW7_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW7_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--quality"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW8_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL2_ROW8_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div className="product-cols__col">
                                <header className="product-cols__header">
                                  <img
                                    src={Image4}
                                    alt="Quality Illustration"
                                  />

                                  <tpnet-heading
                                    text="Satın alma sürecini yönetin"
                                    className="product-cols__heading"
                                  >
                                    <h5 className="no-link">
                                      <FormattedMessage id="LANDING.SECTION2_COL3_HEAD" />
                                    </h5>
                                  </tpnet-heading>
                                </header>

                                <ul className="product-cols__list" role="list">
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--grow"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW1_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW1_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--grow"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW2_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW2_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--grow"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW3_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW3_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--grow"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>{" "}
                                    </div>

                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW4_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW4_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                  <li role="listitem">
                                    <div className="product-icon">
                                      <a className="product-icon__link">
                                        <div
                                          className="product-icon__icon product-icon__icon--grow"
                                          role="presentation"
                                          aria-hidden="true"
                                        >
                                          <FaCheck />
                                        </div>
                                      </a>
                                    </div>
                                    <div className="product-cols__text">
                                      <a className="product-icon__name gc-analytics-event">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW5_HEAD" />
                                      </a>

                                      <p className="product-cols__description">
                                        <FormattedMessage id="LANDING.SECTION2_COL3_ROW5_DESC" />
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className="content-wrapper">
                      <section className="card">
                        <div className="card__content">
                          <header className="card__header">
                            <tpnet-heading className="card__heading">
                              <h2>
                                <FormattedMessage id="LANDING.SECTION3_HEAD" />
                              </h2>
                            </tpnet-heading>
                            <p className="card__subheading">
                              <FormattedMessage id="LANDING.SECTION3_DESC" />
                            </p>
                          </header>

                          <div className="card__subpartial three-column">
                            <div className="three-column__wrapper">
                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION3_COL1_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION3_COL1_DESC" />
                                </p>
                              </div>
                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION3_COL2_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION3_COL2_DESC" />
                                </p>
                              </div>
                              <div className="three-column__column">
                                <tpnet-heading>
                                  <h5 className="no-link">
                                    <FormattedMessage id="LANDING.SECTION3_COL3_HEAD" />
                                  </h5>
                                </tpnet-heading>

                                <p>
                                  <FormattedMessage id="LANDING.SECTION3_COL3_DESC" />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="card__ctas">
                            <div className="card__cta">
                              <a
                                href="https://portal.talepnet.com"
                                className="cta-button gc-analytics-event"
                              >
                                <FormattedMessage id="LANDING.PURCHASING_PORTAL" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <section className="cta-hero-footer">
                      <div className="footer-bg">
                        <img
                          src={Image5}
                          role="presentation"
                          aria-hidden="true"
                          alt="footer"
                        />
                      </div>

                      <div className="content-wrapper">
                        <div className="cta-hero-footer__illo">
                          <img
                            src={Image6}
                            alt="Illustration of developers talking"
                          />
                        </div>

                        <div className="cta-hero-footer__text">
                          <span className="light" aria-level="2">
                            <FormattedMessage id="LANDING.TRY_TALEPNET_HEAD" />
                          </span>
                          <p className="light">
                            <FormattedMessage id="LANDING.TRY_TALEPNET_DESC" />
                          </p>

                          <a
                            href="https://app.talepnet.com/pages/auth/signup"
                            id="ga-get-started-button"
                            className="cta-button cta-button--white cta-button--text-sky-blue gc-analytics-event"
                          >
                            <FormattedMessage id="LANDING.GET_STARTED_FREE" />
                          </a>
                        </div>
                      </div>
                    </section>
                  </main>
                </div>
              </article>
            </article>
          </tpnet-content>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
